.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.post {
  text-align: left;
  width: 400px;
  height: 230px;
  background-color: white;
  margin-bottom: 20px;
  padding: 32px;

  border-radius: 20px;
  background: #FFF;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
}

.post-full {
  text-align: left;
  width: 700px;
  background-color: white;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.post-component {
  color: black;
}

.post-body {
  color: black;
  width: 100%;
  text-align: left;
  padding: 20px;
}

.post-desc {
  color: black;
  width: 100%;
  text-align: left;
  white-space: break-spaces;

}

.main-img {
  width: 500px;
  max-height: 600px;
  padding: 20px;
  padding-left: 0;
}

.separator {
  width: var(--posts-width);
  border-bottom: 1px solid var(--posts-border-color);
}

.profiles-container {
  display: flex;
  flex-wrap: wrap;
  /* Allow cards to wrap to the next line */
  justify-content: space-between;
  /* Distribute cards evenly */
  /*border: 1px solid var(--posts-border-color);
  border-radius: 15px;
  width: var(--posts-width);*/
}

.profiles-container {
  margin: 0 auto;
  /*transform: rotateX(180deg);*/
}

:root {
  --posts-width: 700px;
  --posts-border-color: lightgray;
}

.strategy-btn {
  cursor: pointer;
  align-items: center;
  appearance: none;
  border-style: none;
  box-sizing: border-box;
  color: #3c4043;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-size: 15px;
  height: 40px;
  justify-content: center;
  letter-spacing: .25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px 24px;
  position: relative;
  text-align: center;
  text-transform: none;
  /*transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1), opacity 15ms linear 30ms, transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;*/
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  will-change: transform, opacity;
  z-index: 0;

  border-radius: 48px;
  border: 1px solid rgba(112, 0, 255, 0.25);
  background: #FFF;

  color: #7000FF;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.active-strategy-btn {
  color: white;
  font-weight: bold;
  /*background-color: rgb(51 120 233);*/
}

.score-entry {
  cursor: pointer;
  font-weight: bold;
  color: darkolivegreen;
}

a {
  text-decoration: none;
  color: black;
}

.small-font {
  font-size: 12px;
}

.blue-btn {

  /* Auto layout */
  color: white;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  cursor: pointer;
  width: 133px;
  height: 30px;
  font-size: 13px;

  /* blue/500 */

  background: #3182CE;
  border-radius: 6px;
}

.post-internal-container {}


.flex-end {
  display: absolute;
  top: 0px;
}

.delimiter {
  border-bottom: 1px #EDF2F7 solid;
  width: calc(100% + 40px);
  margin-left: -20px;
}

.review-container {}


.text-input {
  width: 100%;
  min-height: 100px;


  border: 1px solid rgba(112, 0, 255, 0.25);
  /*box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px, rgba(0, 0, 0, .14) 0 6px 10px 0, rgba(0, 0, 0, .12) 0 1px 18px 0;*/
  color: #3c4043;
  padding: 10px;

}

.normal-input {
  width: 100%;
  min-height: 12px;


  border: 1px solid rgba(112, 0, 255, 0.25);
  /*box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px, rgba(0, 0, 0, .14) 0 6px 10px 0, rgba(0, 0, 0, .12) 0 1px 18px 0;*/
  color: #3c4043;
  padding: 10px;
}

.table-1 {
  width: 100%;
}

table.table-1 td,
th {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px #EDF2F7 solid;
}

.primary {
  background-color: var(--color1);
  color: white;
}

.secondary {}

:root {
  --color1: #7000FF;
  --color2: #543A69;
  --color3: #543A69CC;
  --color4: #543A69A6;
  --color5: #543A6980;
  --color5: #F3E3FF;
  --color6: #00A94F;
}

.color2 {
  color: var(--color2);
}

.bg {
  position: fixed;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background: linear-gradient(
      /* Gradient direction (100 degrees from left to right) */
      #DEC3FF,
      /* Color 1 at 100% */
      #ECDFFD,
      /* Color 2 at 24% */
      #F6F6F6,
      /* Color 3 at 0% */
      white);
  background-size: 100% 100%;
}

.snap-img-placeholder {
  border-radius: 8px;
  width: 64px;
  height: 64px;
  background-color: var(--color5);
}

.snap-img-placeholder {
  border-radius: 8px;
  width: 64px;
  height: 64px;
  background-color: var(--color5);
}

.snap-img-placeholder-big {
  border-radius: 8px;
  width: 156px;
  height: 156px;
  background-color: var(--color1);
}

.circle-avatar-small {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  border: 1px solid #FFF;
}

.circle-avatar-placeholder {
  padding-top: 2px;
  font-size: 9px;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  background-color: var(--color5);
}

.post-user {
  text-align: left;
  width: 414px;
  height: 200px;
  background-color: white;
  margin-bottom: 20px;
  padding: 32px;

  border-radius: 20px;
  background: #FFF;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
}

.tab-slot {
  color: rgba(84, 58, 105, 0.80);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer
}

.primary-tab {
  position: absolute;
  display: inline-block;
  background-color: #7000FF;
  height: 4px;
  width: 64px;
  font-weight: bold;
  margin-top: 52px;
  margin-left: -78px;
}

.small-btn {
  font-size: 10px;
  height: 30px;
  min-width: 30px;
  padding: 5px;
}

.search-input {
  padding: 8px 16px;
  align-items: center;
  gap: 16px;
  border-radius: 48px;
  border: 1px solid rgba(112, 0, 255, 0.25);
  background: #FFF;
  color: #7000FF;
  font-size: 16px;
}

.search-input-inner {
  color: #7000FF;
  font-size: 16px;
  border: 0;
  padding: 0
}

.dropdown {
  display: flex;
  padding: 8px 16px;
  justify-content: flex-end;
  align-items: center;
  gap: 32px;
  border-radius: 48px;
  border: 1px solid rgba(112, 0, 255, 0.25);
  background: #FFF;
  color: #7000FF;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdown-list-inner {
  margin-top: 100px;
  position: absolute;
  background-color: white;
  border: 1px solid rgba(112, 0, 255, 0.25);
  min-width: 150px;
  border-radius: 10px;
  padding: 15px;
  z-index: 10000;
}

.dropdown-list-li {
  font-size: 14px;
  cursor: pointer;
  font-weight: bold;
}